import { useState, useEffect } from 'react';
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Product(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [modules, setModules] = useState()

  useEffect(() => {
    isMobile
      ? setModules([Pagination])
      : setModules([Navigation, Pagination])
  }, [isMobile])

  useEffect(() => {
    setIsMobile(window.innerWidth < 768)
  }, [window.innerWidth])

  return (
    <>
      {modules &&
        <>
          <div id={props.id ? props.id : undefined} className='container-fluid' style={{ backgroundColor: '#e3e2de' }}>
            <div className='container'>
              <div className="row pt-2 pt-sm-3 mb-md-5 py-xl-5">
                <div className="col-12 col-xl-6 mt-3 mt-xl-4">
                  <Swiper
                    className='border border-2 mx-0'
                    style={{ userSelect: 'none' }}
                    loop
                    modules={modules}
                    navigation={!isMobile}
                    pagination={{ clickable: true }}
                  >
                    {props.images.map((image, index) =>
                      <SwiperSlide key={index}>
                        <img src={image.file} className="d-block mx-lg-auto img-fluid" alt={image.alt}
                          width={image.width} height={image.height}
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
                <div className="col-12 offset-lg-2 col-lg-8 offset-xl-0 col-xl-6 mt-4">
                  <div className='d-flex flex-column'>
                    <h1 className="display-5 fw-bold lh-1 mb-4 mb-xl-3 py-2 text-center" style={{ backgroundColor: '#4390A4' }}>
                      <span id='product-title' className={'px-2 ' + props.title.replace('.', '').replace('&', 'and').toLowerCase().split(' ').join('-') + '-font-size'}>{props.title}</span>
                    </h1>
                    <span className="lead px-2" style={{ whiteSpace: 'pre-wrap' }}>{props.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row pb-xl-3 mt-xl-4 pt-xl-1'>
              <div className='col-12 offset-lg-2 col-lg-8 offset-xl-1 col-xl-5 mt-3 px-2 mt-md-0 pe-xl-5'>
                <div id='first-prod-attribute' className='d-flex flex-row align-items-center justify-content-center pt-4 mt-2 pt-md-2 mt-md-0 pt-xl-0'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#4390A4" className="bi bi-rulers" viewBox="0 0 16 16">
                    <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z" />
                  </svg>
                  <h2 className='ms-3' style={{ color: '#40403F' }}>Made to measure</h2>
                </div>
                <p className='mt-3' style={{ fontSize: '1.2rem' }}>The blinds we install can be made to fit almost any blind space, no matter how unique.
                  However, you needn't worry about how the product is going to fit, a member of our expert team will visit your property and
                  decide on the best approach.</p>
              </div>
              <div className='col-12 offset-lg-2 col-lg-8 offset-xl-0 col-xl-5 mt-3 px-2 ps-xl-5 mt-xl-0'>
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#4390A4" className="bi bi-broadcast-pin" viewBox="0 0 16 16">
                    <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM6 8a2 2 0 1 1 2.5 1.937V15.5a.5.5 0 0 1-1 0V9.937A2 2 0 0 1 6 8z" />
                  </svg>
                  <h2 className='ms-3' style={{ color: '#40403F' }}>Smart-controlled</h2>
                </div>
                <p className='mt-3' style={{ fontSize: '1.2rem' }}>All of our installs use the latest smart-blind technology,
                  allowing for remote operation using either:<br />
                </p>
                <ol type="A" style={{ fontSize: '1.2rem' }}>
                  <li>a remote handset.</li>
                  <li>a wall-mounted switch.</li>
                  <li>a Smart Device (e.g. Amazon Alexa).</li>
                  <li>a Building Management System (BMS).</li>
                </ol>
              </div>
            </div>
            <div className='row pb-4 pb-md-5 pb-xl-5 mt-xl-4'>
              <div className='col-12 offset-lg-2 col-lg-8 offset-xl-1 col-xl-5 mt-3 px-2 pe-xl-5'>
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#4390A4" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  <h2 className='ms-3' style={{ color: '#40403F' }}>Customisable</h2>
                </div>
                <p className='mt-3' style={{ fontSize: '1.2rem' }}>Each blind comes with a selection of fabrics, multiple methods of remote control and
                  various power supply options, thus allowing each customer to create a product that meets all of their needs and expectations.</p>
              </div>
              <div className='col-12 offset-lg-2 col-lg-8 offset-xl-0 col-xl-5 mt-3 pb-3 px-2 ps-xl-5'>
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#4390A4" className="bi bi-thermometer-half" viewBox="0 0 16 16">
                    <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z" />
                    <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z" />
                  </svg>
                  <h2 className='ms-3' style={{ color: '#40403F' }}>Temperature control</h2>
                </div>
                <p className='mt-3' style={{ fontSize: '1.2rem' }}>The products we fit provide a way for customers to keep their house cool in the summer,
                  by deflecting the sun's rays, and warm in the winter, thanks to hexagonal structures in the blind's fabric which trap heat. </p>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}