import NavDropdown from "./nav-dropdown"
import NavLink from "./nav-item"
import { Dropdown } from 'bootstrap' // do not rm
import { navMenuItems } from "./nav-item-config"
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function NavItems() {
  const [currentNavItem, setCurrentNavItem] = useState(undefined)
  const [areNavItemsLoaded, setAreNavItemsLoaded] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (currentNavItem) {
      setAreNavItemsLoaded(true)
    }
  }, [currentNavItem])

  useEffect(() => {
    setCurrentNavItem(`${location.pathname.replaceAll('/','') || 'home'}-nav-item`)
  }, [location])

  return (
    <>
      {areNavItemsLoaded &&
        <ul className='nav nav-pills my-1 my-sm-0'>
          {navMenuItems.map((menuItem, index) => {
            return !menuItem.dropdownOptions
              ? <NavLink
                key={index}
                id={menuItem.id}
                href={menuItem.href}
                currentNavItem={currentNavItem}
                name={menuItem.name} />
              : <NavDropdown
                key={index}
                menuItem={menuItem}
                currentNavItem={currentNavItem}
              />
          })}
        </ul>
      }
    </>
  )
}