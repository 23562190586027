//import { Link } from 'react-router-dom'
import roofLanternBlind1 from '../../assets/roof-lantern-blinds/roof-lantern-blind-1.jpg'
import roofLanternBlind2 from '../../assets/roof-lantern-blinds/roof-lantern-blind-2.jpg'
import skyLightBlind1 from '../../assets/sky-light-blinds/sky-light-blind-1.jpg'
import Product from '../../common/product/product.js'


// Lantern and Skylight blinds → Lantern and roof blinds
//   → it encompasses tensioned roof light blind, skylight blinds and lantern blinds
export default function LanternAndRoofBlinds() {
  const description = <span>
    <p>
      Roof lanterns and skylights are a stylish addition to any home, they make living spaces more bright and inviting.
    </p>
    <p>
      They are not without their downsides, however. They often let light in when it's not convenient, as well as cause heat loss, as they're situated
      at the highest point of a room. Being positioned so high up adds to the problem, as a traditional manually operated blind would be impractical (perhaps even dangerous) to use.
    </p>
    <p>
      The benefit of smart-controlled blinds is that they provide an effortless
      and convenient way to operate the blind remotely, making controlling the level of light, sound and heat in a property, simple - as it should be.
    </p>
    <p>
      <span className='fst-italic'>Note:</span> we have <a href='/rectangular-blinds/'>regular blinds</a> to match!
    </p>
  </span>

  const images = [
    {
      file: roofLanternBlind1,
      alt: 'Roof Lantern Blind',
      width: 692,
      height: 462
    },
    {
      file: roofLanternBlind2,
      alt: 'Roof Lantern Blind',
      width: 692,
      height: 462
    },
    {
      file: skyLightBlind1,
      alt: 'Skylight Blind',
      width: 692,
      height: 461
    }
  ]

  return (
    <Product
      images={images}
      title='Lantern &amp; Roof Blinds.'
      description={description}
    />
  )
}