//import { Link } from 'react-router-dom';
import logo from '../../../assets/logo/inline-logo.svg'
import NavItems from './nav-items';

export default function Header() {
    return (
      <div className='container'>
        <header className='d-flex flex-wrap justify-content-center align-items-center py-3'>
          <a id='logo-link' href='/' className='d-flex align-items-center mb-md-0 me-md-auto text-dark text-decoration-none'>
            <img height='33' width='330' src={logo} />
          </a>
          <NavItems/>
        </header >
      </div>
    )
  }