import NavLink from './nav-item';

export default function NavDropdown(props) {
  return (
    <li className='nav-item'>
      <button id='product-dropdown-menu' 
        className={'h-100 btn dropdown-toggle' + (props.menuItem.dropdownOptions.find(option => option.id === props.currentNavItem) ? ' dropdown-active' : '')}
        type='button'  data-bs-toggle='dropdown' aria-expanded='false'>
        {props.menuItem.name}
      </button>
      <ul className='dropdown-menu pt-0' aria-labelledby='product-dropdown-menu-options'>
        {props.menuItem.dropdownOptions.map((dropdownOption, index) =>
          <NavLink
            key={index}
            id={dropdownOption.id}
            href={dropdownOption.href}
            currentNavItem={props.currentNavItem}
            name={dropdownOption.name} />
        )}
      </ul>
    </li>
  )
}