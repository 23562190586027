import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import './styles/style.sass'
import Home from './pages/home/home'
import Layout from "./common/layout/layout";
import TriangularBlinds from "./pages/products/triangular-blinds";
import RectangularBlinds from "./pages/products/rectangular-blinds";
import LanternAndRoofBlinds from "./pages/products/lantern-and-roof-blinds";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'triangular-blinds/',
        element: <TriangularBlinds />
      },
      {
        path: 'lantern-and-roof-blinds/',
        element: <LanternAndRoofBlinds />
      },
      {
        path: 'rectangular-blinds/',
        element: <RectangularBlinds />
      },
    ]
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>)