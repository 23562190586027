export const navMenuItems = [
  {
    id: 'home-nav-item',
    href: '/',
    name: 'Home'
  },
  {
    id: 'product-dropdown-nav-item',
    name: 'Products',
    dropdownOptions: [
      {
        id: 'triangular-blinds-nav-item',
        href: '/triangular-blinds/',
        name: 'Triangular Blinds'
      },
      {
        id: 'lantern-and-roof-blinds-nav-item',
        href: '/lantern-and-roof-blinds/',
        name: 'Lantern & Roof Blinds'
      },
      {
        id: 'rectangular-blinds-nav-item',
        href: '/rectangular-blinds/',
        name: 'Rectangular Blinds'
      },
    ]
  },
  // {
  //   id: 'contact-us-nav-item',
  //   href: '/contact-us/',
  //   name: 'Contact Us'
  // }
]