import Product from '../../common/product/product.js'
import honeycombCellularBlind1 from '../../assets/honeycomb-cellular-blinds/honeycomb-cellular-blind-1.png'
import honeycombCellularBlind2 from '../../assets/honeycomb-cellular-blinds/honeycomb-cellular-blind-2.png'
import honeycombCellularBlind3 from '../../assets/honeycomb-cellular-blinds/honeycomb-cellular-blind-3.png'
import rollerBlind1 from '../../assets/roller-blinds/roller-blind-1.png'
import rollerBlind2 from '../../assets/roller-blinds/roller-blind-2.png'

export default function RectangularBlinds() {
  const images = [
    {
      file: honeycombCellularBlind1,
      alt: 'Honeycomb Cellular Blind',
      width: 692,
      height: 461
    },
    {
      file: honeycombCellularBlind2,
      alt: 'Honeycomb Cellular Blind',
      width: 692,
      height: 461
    },
    {
      file: honeycombCellularBlind3,
      alt: 'Honeycomb Cellular Blind',
      width: 692,
      height: 461
    },
    {
      file: rollerBlind1,
      alt: 'Roller Blind',
      width: 692,
      height: 739
    },
    {
      file: rollerBlind2,
      alt: 'Roller Blind',
      width: 692,
      height: 692
    }
  ]

  // mention bi-folds or not?
  const description = <span>
    <p>We offer two styles of blind for rectangular shaped window blinds, Honeycomb Cellular Blinds (aka Duette blinds) and Roller Blinds.</p>
    <p>The Honeycomb Cellular Blinds that we install, are free hanging and sit closer to the window recess than other blinds, making them blackout even more light.</p>
    <p>Our Roller Blinds come with a range of fabrics to choose from and look absolutely stunning during operation!</p>
    <p>When several blinds are fitted, they can be group controlled using a remote handset!</p>
  </span>

  return (
    <Product
      id={'product-rectangular-blinds'}
      title='Rectangular Blinds.'
      images={images}
      description={description}
    />
  )
}