//import { Link } from 'react-router-dom'
import apexBlind1 from '../../assets/apex-blinds/collection-1/apex-blind-c1-1.png'
import apexBlind2 from '../../assets/apex-blinds/collection-1/apex-blind-c1-2.png'
import apexBlind3 from '../../assets/apex-blinds/collection-1/apex-blind-c1-3.png'
import Product from '../../common/product/product.js'

// We specialise in the fitting of smart-controlled triangular shaped blinds, such as glazed gables, apex windows

export default function TriangularBlinds() { // also need to mention shaped blinds here...!
  const descriptionHtml = <span>
    <p>The blinds we install eliminate the three biggest issues faced with glazed gables and apex windows,
      which are:
    </p>
    <ol type='1'>
      <li>they regularly let too much light into a property.</li>
      <li>they often provide little to no privacy.</li>
      <li>there are few practical solutions for solving the above!</li>
    </ol>
    <p>
      Our products provide customers with an effortless way to manage light levels/privacy in their home, automatically - no manual operation required.
      Each blind we install includes the latest smart-blind technology, allowing them to be operated remotely.
    </p>
    <p>
      <span className='fst-italic'>Note:</span> we have <a href='/rectangular-blinds/'>regular blinds</a> to match!
    </p>
  </span>

  const images = [
    {
      file: apexBlind1,
      alt: 'Apex Blind',
      width: 692,
      height: 462
    },
    {
      file: apexBlind2,
      alt: 'Apex Blind',
      width: 692,
      height: 462
    },
    {
      file: apexBlind3,
      alt: 'Apex Blind',
      width: 692,
      height: 462
    }
  ]

  return (
    <Product
      images={images}
      title='Triangular Blinds.'
      description={descriptionHtml}
    />
  )
}