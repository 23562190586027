//import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import apexBlind1 from '../../assets/apex-blinds/collection-1/apex-blind-c1-1.png'
import apexBlind2 from '../../assets/apex-blinds/collection-1/apex-blind-c1-2.png'
import apexBlind3 from '../../assets/apex-blinds/collection-1/apex-blind-c1-3.png'
import roofLanternBlind1 from '../../assets/roof-lantern-blinds/roof-lantern-blind-1.jpg'
import roofLanternBlind2 from '../../assets/roof-lantern-blinds/roof-lantern-blind-2.jpg'
import skyLightBlind1 from '../../assets/sky-light-blinds/sky-light-blind-1.jpg'

const images = [
  {
    file: apexBlind1,
    alt: 'Apex Blind',
    width: 692,
    height: 462
  },
  {
    file: apexBlind2,
    alt: 'Apex Blind',
    width: 692,
    height: 462
  },
  {
    file: apexBlind3,
    alt: 'Apex Blind',
    width: 692,
    height: 462
  },
  {
    file: roofLanternBlind1,
    alt: 'Roof Lantern Blind',
    width: 692,
    height: 462
  },
  {
    file: roofLanternBlind2,
    alt: 'Roof Lantern Blind',
    width: 692,
    height: 462
  },
  {
    file: skyLightBlind1,
    alt: 'Skylight Blind',
    width: 692,
    height: 461
  }
]

export default function HomeHero() {
  return (
    <div id='home-hero-outer-container' className='container-fluid mt-2 pb-sm-4 pb-md-0 pt-lg-3 py-xl-4'>
      <div className='container'>
        <div id='home-hero' className="row flex-lg-row-reverse py-xl-5 px-xl-4 align-items-center rounded-3">
          <div className="col-12 col-lg-6 mt-xl-4">
            <Swiper
              className='border border-2 mx-0'
              style={{ userSelect: 'none' }}
              modules={[Autoplay]}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false
              }}
              rewind={false}
            >
              {images.map((image, index) =>
                <SwiperSlide key={index}>
                  <img src={image.file} className="d-block mx-lg-auto img-fluid" alt={image.alt}
                    width={image.width} height={image.height}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
          <div className="col-12 col-lg-6 text-center text-xl-auto mt-3 mt-4 pt-sm-3 mt-xl-4">
            <h1 className="display-5 fw-bold lh-1 d-inline-block w-100 mb-3 p-2 mb-sm-4" style={{ backgroundColor: '#4390A4', color: '#FFFFFF' }}>
              Blinds redefined.
            </h1>
            <p className="lead" style={{ fontWeight: 400 }}>At Oracle Blinds, we only install products that contain the latest smart-blind technology.
              <br /><br />
              Whatever your needs, we're sure we'll be able to fulfill them.
              Have a look around to see the type of installs that we complete.
              <br /><br />
              If you have an orangery, why not start with our <a href='/lantern-and-roof-blinds/'>Lantern &amp; Roof Blinds</a>?
              For those with glazed gables and apex windows, check out our <a href='/triangular-blinds/'>Triangular Blinds</a>.</p>
          </div>
        </div>
      </div>
    </div>
  )
}