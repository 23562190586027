import Hero from "./home-hero"
import HomeAbout from "./home-about"

export default function Home() {
  return (
    <>
      <Hero />
      <div className='container mt-xl-5'>
        <HomeAbout />
      </div>
    </>
  )
}